<template>
  <div class="dashboard-container" v-loading="loading">
    <el-row :gutter="20" class="item-row">
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.new_customer_num?info.new_customer_num:0}}</span>
            <span>今日新增注册客户数</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.new_activity_num?info.new_activity_num:0}}</span>
            <span>今日新增活动数量</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.new_agenty_num?info.new_agenty_num:0}}</span>
            <span>今日新增代理人数量</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.new_shop_num?info.new_shop_num:0}}</span>
            <span>今日新增商户数量</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="item-row">
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.new_order_num?info.new_order_num:0}}</span>
            <span>今日订单总数</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.real_money_t?info.real_money_t:0 | unitPrice()}}</span>
            <span>今日营收总额(元)</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.real_money_l?info.real_money_l:0 | unitPrice()}}</span>
            <span>昨日营收总额(元)</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.real_money_w?info.real_money_w:0 | unitPrice()}}</span>
            <span>近7天营收总额(元)</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="elcard">
          <div slot="header" class="clearfix">
            <span>待处理事务</span>
          </div>
          <router-link tag="div" class="item" to="/merchant/merchant-apply-list">
            <span>申请成为商户信息审核</span>
            <span>({{info.shop_audit_num?info.shop_audit_num:0}})</span>
          </router-link>
          <router-link tag="div" class="item" to="/activity/activity-review">
            <span>待审核活动</span>
            <span>({{info.activity_audit_num?info.activity_audit_num:0}})</span>
          </router-link>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="elcard">
          <div slot="header" class="clearfix">
            <span>预警信息</span>
          </div>
          <router-link tag="div" class="item" to="/merchant/merchant-refund-activity-top5">
            <span>商户退款活动top5</span>
          </router-link>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>订单统计</p>
            <p>
              <span :class="active==='lastday'?'active':'no-active'" @click="handleTime(1)">昨日</span>
              <span :class="active==='day'?'active':'no-active'" @click="handleTime(0)">今日</span>
              <span :class="active==='week'?'active':'no-active'" @click="handleTime(7)">近7天</span>
              <span :class="active==='mouth'?'active':'no-active'" @click="handleTime(30)">近30天</span>
              <el-select
                class="ipt-default"
                style="width:120px"
                v-model="advancedForm1.orderStatus"
                clearable
                placeholder="订单状态"
                @change="handleOrderStatusChange"
              >
                <el-option value="CONFIRM" label="待付款"></el-option>
                <el-option value="PAID_OFF" label="待参加"></el-option>
                <el-option value="IN" label="参加中"></el-option>
                <el-option value="AFTER_SALE" label="售后"></el-option>
              </el-select>
              <el-date-picker
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                style="width: 250px;margin-left: 10px;"
                v-model="advancedForm1.times"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="{disabledDate: MixinDisabledDate}"
                @change="handleOrderTimeChange"
              ></el-date-picker>
            </p>
          </div>
          <div class="echart-alltitle">
            <div class="echarts-left1">
              <div class="echarts-left">
                <h2>{{orderA.order_sum}}</h2>
                <p>{{activeName}}订单总数(笔)</p>
              </div>
              <div class="echarts-left">
                <h2>￥{{orderA.order_paysum}}</h2>
                <p>{{activeName}}订单总额</p>
              </div>
              <div class="echarts-left">
                <h2>￥{{orderA.order_reality_paysum}}</h2>
                <p>{{activeName}}净收入金额</p>
              </div>
            </div>
            <div :span="20" id="echarts_right" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 0">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="clearfix echart-title">
            <p>退款订单统计</p>
            <p>
              <span :class="active2==='lastday'?'active':'no-active'" @click="handleTime2(1)">昨日</span>
              <span :class="active2==='day'?'active':'no-active'" @click="handleTime2(0)">今日</span>
              <span :class="active2==='week'?'active':'no-active'" @click="handleTime2(7)">近7天</span>
              <span :class="active2==='mouth'?'active':'no-active'" @click="handleTime2(30)">近30天</span>
              <el-date-picker
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                style="width: 250px;margin-left: 10px;"
                v-model="advancedForm2.times"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="{disabledDate: MixinDisabledDate}"
                @change="handleDistriTimeChange"
              ></el-date-picker>
            </p>
          </div>
          <div class="echart-alltitle">
            <div class="echarts-left1">
              <div class="echarts-left">
                <h2>{{orderB.refund_sum}}</h2>
                <p>{{activeName2}}退款订单数(笔)</p>
              </div>
              <div class="echarts-left">
                <h2>￥{{orderB.refundpaysum}}</h2>
                <p>{{activeName2}}退款总额</p>
              </div>
            </div>
            <div :span="20" id="echarts_right2" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as API_Home from "@/api/home";

export default {
  name: "dashboard",
  data() {
    return {
      loading: true,
      goodsList: null,
      memberList: null,
      /** 基本数据 */
      info: {},
      advancedForm1: {
        times: "",
      },
      // 订单统计
      active: "day",
      activeName: "今日",
      // 退款订单统计
      active2: "day",
      activeName2: "今日",
      advancedForm2: {
        times: "",
      },
      orderA: '',
      orderB: '',
    };
  },
  filters: {
    secrecyMobile(mobile) {
      mobile = String(mobile);
      if (!/\d{11}/.test(mobile)) {
        return mobile;
      }
      return mobile.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
    },
  },
  created() {
    this.loading = true;
    API_Home.getPage()
      .then((res) => {
        this.info = this.MixinClone(res.sales_quantity_total);
        this.loading = false;
      })
      .catch((_) => {
        this.loading = false;
      });
  },
  activated() {},
  mounted() {
    this.$nextTick(() => {
      this.sesalChart = this.$echarts.init(
        document.getElementById("echarts_right")
      );
      this.sesalChart2 = this.$echarts.init(
        document.getElementById("echarts_right2")
      );
    });
    this.handleTime(0);
    this.handleTime2(0);
    // API_Common.getIndexData().then((response) => {
    //   this.loading = false;
    //   this.goodsList = response.goods_vos;
    //   this.memberList = response.member_vos;

    //   /** 销售统计 */
    //   const IS = response.sales_total;
    //   const nowDate = new Date();
    //   const year = nowDate.getFullYear();
    //   const month = nowDate.getMonth() + 1;
    //   this.sesalChart.setOption({
    //     title: {
    //       text: `${year}年${month}月销售统计`,
    //       subtext: '单位(元)',
    //       x: 'center',
    //     },
    //     tooltip: { trigger: 'item', formatter: '{a} <br/>{b} : {c} ({d}%)' },
    //     legend: {
    //       orient: 'vertical',
    //       left: 'left',
    //       data: ['收款金额', '实收金额', '退款金额'],
    //     },
    //     series: [
    //       {
    //         name: '访问来源',
    //         type: 'pie',
    //         radius: '55%',
    //         center: ['55%', '70%'],
    //         data: [
    //           { value: IS.receive_money, name: '收款金额' },
    //           { value: IS.real_money, name: '实收金额' },
    //           { value: IS.refund_money, name: '退款金额' },
    //         ],
    //         itemStyle: {
    //           emphasis: {
    //             shadowBlur: 10,
    //             shadowOffsetX: 0,
    //             shadowColor: 'rgba(0, 0, 0, 0.5)',
    //           },
    //         },
    //       },
    //     ],
    //   });
    // });
  },
  methods: {
    handleOrderStatusChange() {
      this.Get_list();
    },
    handleOrderTimeChange(v) {
      this.active = 1;
      this.Get_list();
    },
    handleDistriTimeChange(v) {
      this.active2 = 1;
      this.Get_list_refund();
    },
    handleTime(has) {
      let end = new Date();
      let start;
      if (has === 1) {
        // 昨天
        start = new Date().setHours(0, 0, 0, 0) - 1 * 24 * 60 * 60 * 1000;
        end.setTime(end.getTime() - 24 * 60 * 60 * 1000)
        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(59)
        // end = end.getTime()
        this.active = "lastday";
        this.activeName = "昨日";
        end = this.MixinEndYesterday;
      } else if (has === 7) {
        // 近七天
        start = new Date().setHours(0, 0, 0, 0) - 6 * 24 * 60 * 60 * 1000;
        this.active = "week";
        this.activeName = "近7天";
      } else if (has === 30) {
        // 近30天
        start = new Date().setHours(0, 0, 0, 0) - 29 * 24 * 60 * 60 * 1000;
        this.active = "mouth";
        this.activeName = "近30天";
      } else if (has === 0) {
        // 今天
        start = new Date().setHours(0, 0, 0, 0);
        this.active = "day";
        this.activeName = "今日";
        end = this.MixinEndToday;
      }
      this.advancedForm1.times = [start, end];
      this.Get_list();
    },
    handleTime2(has) {
      let end = new Date();
      let start;
      if (has === 1) {
        // 昨天
        start = new Date().setHours(0, 0, 0, 0) - 1 * 24 * 60 * 60 * 1000;
        end.setTime(end.getTime() - 24 * 60 * 60 * 1000)
        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(59)
        this.active2 = "lastday";
        this.activeName2 = "昨日";
        end = this.MixinEndYesterday;
      } else if (has === 7) {
        // 近七天
        start = new Date().setHours(0, 0, 0, 0) - 6 * 24 * 60 * 60 * 1000;
        this.active2 = "week";
        this.activeName2 = "近7天";
      } else if (has === 30) {
        // 近30天
        start = new Date().setHours(0, 0, 0, 0) - 29 * 24 * 60 * 60 * 1000;
        this.active2 = "mouth";
        this.activeName2 = "近30天";
      } else if (has === 0) {
        // 今天
        start = new Date().setHours(0, 0, 0, 0);
        this.active2 = "day";
        this.activeName2 = "今日";
        end = this.MixinEndToday;
      }
      this.advancedForm2.times = [start, end];
      this.Get_list_refund();
    },
    Get_list() {
      const start = parseInt(this.advancedForm1.times[0] / 1000);
      const end = parseInt(this.advancedForm1.times[1] / 1000);
      console.log(start, end);
      API_Home.getOrderStatics({
        start,
        end,
        orderStatus: this.advancedForm1.orderStatus
      }).then((res) => {
        this.loading = false;
        this.orderA = res
        const x = res.orderchart.xAxis;
        const s = res.orderchart.series;
        const map = new Map()
        x.forEach((e, i) => {
          const v = [s[0].localName[i] ? s[0].localName[i].split(',')[0] : '0', s[0].localName[i] ? s[0].localName[i].split(',')[1] : '0']
          map.set(e, v)
        });
        const mapDistri = new Map()
        x.forEach((e, i) => {
          const v = [s[1].localName[i] ? s[1].localName[i].split(',')[0] : '0', s[1].localName[i] ? s[1].localName[i].split(',')[1] : '0']
          mapDistri.set(e, v)
        });
        this.sesalChart.setOption({
          backgroundColor: "#fff", // 背景色
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: x,
          },
          yAxis: {
            name: "",
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          legend: {
            orient: "vertical",
            x: "right",
            y: "top",
            data: [s[0].name, s[1].name],
          },
          tooltip: {
            trigger: "axis",
            formatter(params) {
              const [p1, p2] = params
              var time, colora, colorb, a, b, c, d, e, f;
              time = p1.axisValueLabel
              colora = p1.color
              colorb = p2.color
              a = p1.value
              b = p2.value
              c = map.get(p1.axisValue)[0]
              d = mapDistri.get(p2.axisValue)[0]
              e = map.get(p1.axisValue)[1]
              f = mapDistri.get(p2.axisValue)[1]
              // console.log(params, 'params');
              return `<div style="width:100%;height:100;">
                        <div style="display:flex;justify-content:center;align-items:center;padding-top: 8px;">${time}</div>
                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>订单数：</div>
                            </div>
                            <div class="item-value">
                              ${a}
                            </div>
                          </div>
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销订单数：</div>
                            </div>
                            <div class="item-value">
                             ${b}
                            </div>
                          </div>
                        </div>

                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>订单总额：</div>
                            </div>
                            <div class="item-value">
                              ￥${c}
                            </div>
                          </div>
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销订单总额：</div>
                            </div>
                            <div class="item-value">
                              ￥${d}
                            </div>
                          </div>
                        </div>

                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;padding-bottom: 8px">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>订单净收入：</div>
                            </div>
                            <div class="item-value">
                              ￥${e}
                            </div>
                          </div>
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销订单佣金支出：</div>
                            </div>
                            <div class="item-value">
                              ￥${f}
                            </div>
                          </div>
                        </div>
                      </div>`
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              name: s[0].name,
              data: s[0].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
            {
              name: s[1].name,
              data: s[1].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
          ],
        });
      });
    },
    Get_list_refund() {
      const start = parseInt(this.advancedForm2.times[0] / 1000);
      const end = parseInt(this.advancedForm2.times[1] / 1000);
      console.log(start, end);
      API_Home.getRefundStatics({
        start,
        end,
      }).then((res) => {
        this.loading = false;
        this.orderB = res
        const x = res.refundchart.xAxis;
        const s = res.refundchart.series;
        const map = new Map()
        x.forEach((e, i) => {
          const v = [s[0].localName[i] ? s[0].localName[i].split(',')[0] : '0', s[0].localName[i] ? s[0].localName[i].split(',')[1] : '0']
          map.set(e, v)
        });
        const mapDistri = new Map()
        x.forEach((e, i) => {
          const v = [s[1].localName[i] ? s[1].localName[i].split(',')[0] : '0', s[1].localName[i] ? s[1].localName[i].split(',')[1] : '0']
          mapDistri.set(e, v)
        });
        this.sesalChart2.setOption({
          backgroundColor: "#fff", // 背景色
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: x,
          },
          yAxis: {
            name: "",
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          legend: {
            orient: "vertical",
            x: "right",
            y: "top",
            data: [s[0].name, s[1].name],
          },
          tooltip: {
            trigger: "axis",
            formatter(params) {
              const [p1, p2] = params
              var time, colora, colorb, a, b, c, d, e, f;
              time = p1.axisValueLabel
              colora = p1.color
              colorb = p2.color
              a = p1.value
              b = p2.value
              c = map.get(p1.axisValue)[0]
              d = mapDistri.get(p2.axisValue)[0]
              e = map.get(p1.axisValue)[1]
              f = mapDistri.get(p2.axisValue)[1]
              // console.log(params, 'params');
              return `<div style="width:100%;height:100;">
                        <div style="display:flex;justify-content:center;align-items:center;padding-top: 8px;">${time}</div>
                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>订单数：</div>
                            </div>
                            <div class="item-value">
                              ${a}
                            </div>
                          </div>
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销订单数：</div>
                            </div>
                            <div class="item-value">
                             ${b}
                            </div>
                          </div>
                        </div>

                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>退款总额：</div>
                            </div>
                            <div class="item-value">
                              ￥${c}
                            </div>
                          </div>
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销退款总额：</div>
                            </div>
                            <div class="item-value">
                              ￥${d}
                            </div>
                          </div>
                        </div>

                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;padding-bottom: 8px">
                          <div style="padding:0 20px;width:0%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:0px;height:0px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div></div>
                            </div>
                            <div class="item-value">
                            </div>
                          </div>
                          <div style="padding:0 20px;width:60%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销订单佣金收回：</div>
                            </div>
                            <div class="item-value">
                              ￥${f}
                            </div>
                          </div>
                        </div>
                      </div>`
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              name: s[0].name,
              data: s[0].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
            {
              name: s[1].name,
              data: s[1].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
          ],
        });
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.item-row {
  margin-bottom: 10px;
  .el-col {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
.item_list {
  background: #ffffff;
  border-radius: 12px;
  margin: 0 5px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    margin-top: 22px;
  }
  div {
    span {
      display: block;
    }
    span:nth-child(1) {
      color: #212121;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #434343;
    }
  }
  img {
    width: 82px;
    height: 82px;
  }
}
.echart-title::after {
  display: none;
}
.echart-title {
  height: 50px;
  font-size: 18px;
  color: #007f7f;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:nth-child(2) {
    color: #212121;
    font-size: 14px;
  }
  p span {
    cursor: pointer;
    line-height: 20px;
    height: 24px;
    display: inline-table;
    margin: 0 5px;
  }

  p span.active {
    color: #007f7f;
    border-bottom: 2px solid #007f7f;
  }
}

.echart-alltitle {
  display: flex;
  justify-content: space-around;
}
.echarts-left1 {
  width: 15%;
}
.echarts-left {
  height: 100px;
  width: 100%;
  background: rgba(51, 205, 189, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 0px;
    color: #212121;
  }
  p {
    font-size: 15px;
    line-height: 0px;
    font-weight: 500;
    margin-top: -10px;
    color: #656565;
  }
}
.echarts-left:nth-child(2),
.echarts-left:nth-child(3) {
  background: rgba(253, 85, 87, 0.08);
}
.echarts-right {
  width: 80%;
  height: 350px;
}

.elcard {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background: #f3f5f7;
    border-radius: 8px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    padding: 0 5px;
    cursor: pointer;
  }
}

.dashboard-container {
  height: 100%;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .goods-statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    a {
      display: block;
      margin: 0;
      width: 75%;
      button {
        width: 100%;
      }
    }
  }
  .goods-image {
    width: 50px;
    height: 50px;
  }
}
.el-row {
  position: relative;
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
</style>
